.step-layout {
  --clr-primary: #007bff;
  --clr-white: #fff;

  --bg-primary: #375eeb;
  --bg-dark: #001a3c;
  --bg-grey: #d9d9d9;

  --section-spacing: 2.4em;
  --title-gutter: clamp(1.5rem, 2vw + 0.8rem, 3rem);

  --fs-900: clamp(1.8rem, 2vw + 1rem, 4.4rem);
  --fs-700: clamp(1.75rem, 2vw + 0.9rem, 4rem);
  --fs-600: clamp(0.6rem, 1vw + 0.7rem, 1.45rem);
  --fs-500: clamp(0.5rem, 1vw + 0.5rem, 1.2rem);
  display: flex;
  --layout-spacing: clamp(1rem, 3vw + 1rem, 3rem);
  --layout-spacing-lg: calc(var(--layout-spacing) * 2);

  min-height: calc(100vh - 80px);
  overflow-x: hidden;
}
.step-layout-container {
  padding: var(--layout-spacing-lg) var(--layout-spacing);
}
.step-layout-container > * + * {
  margin-top: var(--layout-spacing);
}

.step-layout-left {
  flex: 1;
  background-blend-mode: multiply;
  background-size: cover;
  display: flex;
  flex-direction: column;
}
.step-layout-left .step-layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.step-layout-right .title {
  margin-top: -11px;
  text-align: center;
  font-weight: bold;
}

.step-layout-right {
  flex-basis: 500px;
}

.step-layout-left .intro-title {
  text-align: center !important;
  margin-top: 0px !important;
}
.step-layout-right .title {
  text-align: left;
}
.submit-btn-wrapper {
  margin-top: var(--layout-spacing);
}
.step-layout .form-group {
  margin-bottom: 20px;
}
.step-layout .form-control {
  padding: 0.7rem 1rem;
  font-size: 1rem;
  border-radius: var(--bs-border-radius-lg);
}
