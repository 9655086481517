.property-card {
    --fs-sm: 12px;
    --fs-md: 14px;
    --primary-clr: #0061df;
    padding: 10px;
    border-radius: 10px;
    background-color: #f7f7f7;
}
.property-card section {
    padding: 0;
}
.property-card .carousel .carousel-inner {
    cursor: pointer;
}
.property-card .carousel img {
    border-radius: 10px;
    height: 240px;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}
.property-card .carousel .carousel-control-prev, .property-card .carousel .carousel-control-next {
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.65);
    border-radius: 10px;
    top: 50%;
    opacity: 1;
    transition: opacity 0.1s ease-in-out 0s;
    transform: translateY(-50%);
}
.property-card .carousel .carousel-control-prev > span, .property-card .carousel .carousel-control-next > span {
    display: none;
}
.property-card .carousel .carousel-control-prev:hover, .property-card .carousel .carousel-control-next:hover {
    border: 2px solid var(--primary-clr);
}
.property-card .carousel .carousel-control-next {
    right: 5px;
}
.property-card .carousel .carousel-control-next::before {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-family: "Font Awesome 5 Free";
    color: var(--primary-clr);
    font-weight: 600;
    font-size: 20px;
}
.property-card .carousel .carousel-control-prev {
    left: 5px;
}
.property-card .carousel .carousel-control-prev::before {
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    color: var(--primary-clr);
    font-weight: 600;
    font-size: 20px;
}
.property-card .carousel .carousel-indicators {
    margin-bottom: 0px;
}
.property-card .carousel .carousel-indicators li {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}
.property-card .carousel .carousel-indicators li.active {
    background-color: var(--primary-clr);
}
.property-card .top-label {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(1px);
    padding: 0px 5px;
    color: var(--primary-clr);
    font-weight: 500;
    font-size: 15px;
    position: absolute;
    top: 10px;
    z-index: 1;
}
.property-card .top-label--left {
    left: 10px;
}
.property-card .top-label--right {
    right: 10px;
}
.property-card .top-label .fav-btn {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0px;
}
.property-card .top-label .fav-btn .fa-heart {
    color: var(--primary-clr);
}
.property-card .property-card__body .main-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin: 15px 0px;
    padding: 0 5px;
    font-size: var(--fs-md);
}
.property-card .property-card__body .main-info .address-price-sec {
    flex: 1;
}
.property-card .property-card__body .main-info .address-price-sec .price {
    font-family: helvetica;
    font-size: 2.15em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.property-card .property-card__body .main-info .address-price-sec .construction-name {
    font-size: 1.2em;
}
.property-card .property-card__body .main-info .address-price-sec .address {
    color: #999;
    font-family: helvetica;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-size: 1em;
    text-align: start;
}
.property-card .property-card__body .main-info .eye-circle-btn {
    width: 3.7em;
    height: 3.7em;
    outline: none;
    border: none;
    background-color: #fff;
    border-radius: 50%;
    padding: 0px;
}
.property-card .property-card__body .main-info .eye-circle-btn svg {
    width: 1.5em;
}
.property-card .sold-info {
    display: flex;
    background: #d1dff6;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size: var(--fs-sm);
}
.property-card .sold-info .sold-info-item {
    flex: 1;
    flex-basis: auto;
    position: relative;
}
.property-card .sold-info .sold-info-item label {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 1em;
    color: var(--primary-clr);
}
.property-card .sold-info .sold-info-item p {
    font-size: 0.86em;
    margin-bottom: 0;
}
.property-card .sold-info .sold-info-item:not(:last-child)::after {
    content: "";
    height: 100%;
    margin: 0 13px;
    position: absolute;
    top: 0;
    right: 0;
    border-left: 1.5px solid #fff;
}
.property-card .property-card__footer {
    border-top: 1px solid #d9d9d9;
    padding-top: 10px;
    padding-bottom: 5px;
}
.property-card .property-card__footer ul {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    font-size: var(--fs-sm);
}
.property-card .property-card__footer ul li {
    display: flex;
    flex: 1;
    align-items: center;
    gap: 8px;
    font-size: 0.95em;
}
.property-card .property-card__footer ul li svg {
    width: 1.9em;
}
.property-card .property-card__footer ul li .garage-svg {
    width: 2.2em;
}
@media (min-width: 1200px) {
    .property-card {
        --fs-sm: 14px;
        --fs-md: 15px;
   }
}
@media (max-width: 768px) {
    .property-card {
        --fs-sm: 14px;
        --fs-md: 15px;
   }
}
