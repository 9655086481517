.new_into_offer_title h5 {
  color: #0061df;
}

.new_into_offer_title {
  text-align: center;
  margin-bottom: 45px;
}

.new_into_offer {
  padding: 50px 0;
}
.new_into_offer_box_button {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

.new_into_offer_box_button ul {
  width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 6px;
}

.new_into_offer_box_button ul li a.active {
  background: #0061df !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 7px;
}

.new_into_offer_box_button ul li a {
  color: #707070;
  padding: 0.5rem 1.8rem;
}
.new_property_card_image img {
  width: 100%;
}
.new_property_card_content {
  padding: 14px;
}

.new_property_card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  height: 100%;
}

.new_property_card_title span {
  display: block;
  color: #4b4b4b;
  font-size: 16px;
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.new_property_card_ame ul li {
  color: #373738;
  font-size: 14px;
  width: 50%;
  display: flex;
  align-items: center;
}

.new_property_card_ame ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.new_property_card_ame ul li span {
  display: block;
  line-height: 0;
  margin-right: 2px;
}

.new_property_card_ame ul li span img {
  width: 26px;
}

.new_property_card_ame {
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.new_property_card_p p {
  color: #373738;

  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.new_property_card_p span {
  display: block;
  color: #373738;
  font-size: 16px;
}

.new_property_card_p span small {
  font-size: 16px;
  text-decoration: line-through;
}

.new_into_offer_box_see_more {
  text-align: center;
  margin-top: 50px;
}

.new_into_offer_box_see_more .seemore {
  color: #0061df;

  font-size: 22px;
  text-decoration: underline;
  border: none;
  background: transparent;
}
#market_trend_chart {
  width: 1200px;
  height: 400px;
}
.new_property_card_image {
  height: 165px;
}
.property_feature_listings_box_item {
  overflow: hidden;
  border: 1px solid #dddddd;
  cursor: pointer;
}

.property_feature_listings_box_item span.type_point {
  font-size: 12px;
  background: #0061df;
  border-radius: 0;
  padding: 3px 14px;
  text-transform: uppercase;
}

.property_feature_listings_box_item span.sale {
  position: absolute;
  top: 13px;
  color: #fff;
  padding: 3px 10px;
  right: 12px;
  font-size: 12px;
  background: #414141;
  border-radius: 0;
}

.property_feature_listings_box_item span.image {
  position: absolute;
  left: 25px;
  bottom: 15px;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  visibility: hidden;
}

.property_feature_listings_box_item_image {
  position: relative;
}

.property_feature_listings_box_item span.video {
  position: absolute;
  bottom: 15px;
  left: 56px;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  margin-left: 19px;
  visibility: hidden;
}

.property_feature_listings_box_item span.video img {
  margin-right: 5px;
  margin-top: -2px;
}

.property_feature_listings_box_item span.image img {
  margin-right: 5px;
  margin-top: -2px;
}

.property_feature_listings_box_item_content {
  margin: 20px 12px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 10px;
}

.property_feature_listings_box_item_content span.location {
  letter-spacing: 1px;
  font-size: 15px;
}

.property_feature_listings_box_item_bottomcontent {
  display: flex;
  align-items: baseline;
  flex-flow: wrap;
  margin: 20px 12px 0;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 7px;
}

.property_feature_listings_box_item_bottomcontent span {
  width: 50%;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.property_feature_listings_box_item_bottomcontent span img {
  width: 19px !important;
  margin-right: 7px;
}

.property_feature_listings_box_item_price {
  margin: 14px 12px;
}

.property_feature_listings_box_item_price span {
  font-weight: 600;
  color: #414141;
  letter-spacing: 1px;
  font-size: 19px;
}

.property_feature_listings_box_item:hover
  .property_feature_listings_box_item_image:before {
  content: "";
  background: #00000054;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.property_feature_listings_box_item:hover.property_feature_listings_box_item
  span.image {
  visibility: visible;
}

.property_feature_listings_box_item:hover.property_feature_listings_box_item
  span.video {
  visibility: visible;
}

.property_feature_listings_box_item:hover {
  box-shadow: 0 0 7px 3px #00000040;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.feature_residental_list .property_feature_listings_box_item {
  margin: 12px 10px;
}

.featured_listings.new_feature .featured_listings_title {
  text-align: center;
}

.featured_listings.new_feature .featured_listings_title:before {
  content: none;
}

.featured_listings.new_feature .featured_listings_title h4 {
  font-size: 32px;
}

.featured_listings.new_feature .featured_listings_title span {
  display: block;
  font-size: 15px;
  margin-top: 9px;
}

.new_feature_tab ul#pills-tab {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 29px;
  gap: 20px;
}

.new_feature_tab ul#pills-tab .nav-link {
  border: 1px solid #0261df;
  border-radius: 0;
  text-align: center;
  color: #0061df;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.new_feature_tab ul#pills-tab li:not(:last-child) {
  /* margin-right: 30px; */
}

.new_feature_tab ul#pills-tab .nav-link.active {
  color: #fff;
  background: #0061df;
}

.new_feature_tab {
  margin-top: 25px;
}

.new_feature_tab ul#pills-tab .nav-link:hover {
  background: #0061df;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#pills-currently_for_sale .new_property_card_image img {
  width: 100%;
}
#pills-currently_for_sale .new_property_card_content {
  padding: 14px;
}

#pills-currently_for_sale .new_property_card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  height: 100%;
}

#pills-currently_for_sale .new_property_card_title span {
  display: block;
  color: #4b4b4b;
  font-size: 16px;
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

#pills-currently_for_sale .new_property_card_ame ul li {
  color: #373738;
  font-size: 14px;
  width: 50%;
  display: flex;
  align-items: center;
}

#pills-currently_for_sale .new_property_card_ame ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

#pills-currently_for_sale .new_property_card_ame ul li span {
  display: block;
  line-height: 0;
  margin-right: 2px;
}

#pills-currently_for_sale .new_property_card_ame ul li span img {
  width: 26px;
}

#pills-currently_for_sale .new_property_card_ame {
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

#pills-currently_for_sale .new_property_card_p p {
  color: #373738;

  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 5px;
}

#pills-currently_for_sale .new_property_card_p span {
  display: block;
  color: #373738;
  font-size: 16px;
}

#pills-currently_for_sale .new_property_card_p span small {
  font-size: 16px;
  text-decoration: line-through;
}

#pills-currently_for_sale .new_property_card_image {
  position: relative;
}
#pills-currently_for_sale .new_property_card_image img {
  height: 170px;
}
#pills-currently_for_sale .new_property_card_image span.detatched_new {
  position: absolute;
  top: 10px;
  background: #ffffffd9;
  font-size: 13px;
  padding: 3px 9px;
  left: 14px;
  border-radius: 5px;
}
#pills-currently_for_sale .new_property_card_image span.sale_new {
  position: absolute;
  top: 10px;
  background: #ffffffd9;
  font-size: 13px;
  padding: 3px 9px;
  right: 14px;
  border-radius: 5px;
}
#pills-currently_for_sale .new_property_card_image span.hour_new {
  position: absolute;
  bottom: 10px;
  background: #ffffffd9;
  font-size: 13px;
  padding: 3px 9px;
  right: 14px;
  border-radius: 5px;
}
.pills-currently_for_sale_row {
  margin-bottom: 30px !important;
}
.common-property-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 10px;
}
