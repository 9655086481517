.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
.skeleton-card {
  width: 300px;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  background: #fff;
}

.skeleton-card .img {
  height: 200px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.skeleton-card .address {
  height: 1rem;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.skeleton-card .feature-grid {
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 20px;
  grid-auto-rows: 25px;
}

.skeleton-card .price {
  height: 30px;
  margin: 10px;
  width: 70%;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
