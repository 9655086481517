.filter-list-header {
  --clr-primary: #0061df;
  background: #fff;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  height: 72px;
  display: flex;
  align-items: center;
}
.filter-list-header-container {
  display: flex;
  align-items: center;
}
.filter-list-header-container .dropdown-item:focus {
  background-color: var(  --clr-primary);
  color: #fff!important;
}
.search-box {
  height: 55px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 3px;
  position: relative;
}
.search-box .fa-map-marker-alt {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.search-box input {
  flex: 1;
  border: none;
  box-shadow: none;
  outline: none;
}
.search-box .dropdown {
  margin-right: 5px;
}

.search-box .dropdown > button {
  background: transparent;
  color: var(--clr-primary);
}
.search-box .search-btn {
  border-radius: 10px;
  background: var(--clr-primary);
  box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
  height: calc(100% - 6px);
  color: #fff;
  border: none;
  padding-inline: 20px;
  display: flex;
  align-items: center;
}

button,
a,
button:hover,
a:hover,
button:focus,
a:focus {
  border: none;
  text-decoration: none;
}
.text-primary {
  color: var(--clr-primary) !important;
}
.btn-md {
  padding: 0 20px;
  font-weight: 600;
  font-size: 14px;
  height: 50px;
  border-radius: 10px;
}
.btn-grey {
  background: #f2f2f2;
  color: var(--clr-primary) !important;
  outline: none;
  border: none;
}
.btn-grey:not(.active):hover {
  background: #edebeb !important;
}
.filter-sort-btn {
  background: transparent;
  color: var(--clr-primary);
  outline: none !important;
  border: none !important;
  padding-inline: 14px;
}
.filter-sort-btn:hover,
.filter-sort-btn.show,
.filter-sort-btn:active {
  background: #f2f2f2!important;
  color: var(--clr-primary)!important;
}
.exclusive-listing-btn {
  box-shadow: 0px 0px 4px 0px rgba(252, 161, 32, 0.1);
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 6px;
}
.exclusive-listing-btn,
.exclusive-listing-btn:hover {
  background: #fca120;
  color: #fff;
}
.map-search-btn {
  box-shadow: 1px 1px 4px 0px rgba(0, 97, 223, 0.3);
  display: flex;
  align-items: center;
  gap: 6px;
}
.map-search-btn,
.map-search-btn:hover {
  background: var(--clr-primary);
  color: #fff;
}
.city-dropdown {
  max-height: 250px;
  overflow-y: auto;
  margin-top: 2px;
  position: absolute;
  z-index: 2;
  width: 100%;
  border: 1px solid grey;
  top: 100%;
  left: 0;
  display: block;
  border-radius: 8px;
}
.city-dropdown a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.filter-field__options {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
}
.filter-field__options button {
  padding: 6px 14px;
  border-radius: 5px;
  line-height: normal;
}
.filter-field__options .active {
  background: var(--clr-primary);
  color: #fff !important;
}
.dropdown__filter .dropdown-menu {
  min-width: 600px;
  border-radius: 0px 0px 15px 15px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 25px;
}
.dropdown__filter .dropdown-menu * {
  font-weight: 500;
  font-size: 14px;
  color: #6e6e73;
}
.dropdown__filter .dropdown-menu label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}
.dropdown__filter .dropdown-menu .price-range-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdown__filter .dropdown-menu .price-range-select-group {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dropdown__filter .dropdown-menu .price-range-select-group .form-group {
  margin-bottom: 0 !important;
}
.dropdown__filter .dropdown-menu .price-range-select-group select {
  border-radius: 5px !important;
  border: 1px solid var(--clr-primary) !important;
  padding: 0px 10px !important;
  height: 32px !important;
  font-size: 14px !important;
  color: #6e6e73 !important;
}
.dropdown__filter .dropdown-menu .bed-bath-parking-group {
  display: grid;
  gap: 1rem;
}
.sort-by-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-inline: 10px;
}
.sort-by-options button {
  white-space: nowrap;
}
@media (min-width: 992px) {
  .bed-bath-parking-group {
    grid-template-columns: repeat(2, 1fr);
  }
  .bed-bath-parking-group .filter-field__options {
    flex-wrap: nowrap !important;
  }
  .search-box {
    min-width: 376px;
  }
}
@media (max-width: 575.98px) {
  .filter-top-sec {
    margin-top: 72px !important;
  }
  .filter-list-header {
    height: 140px;
  }
  .filter-list-header-container {
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: space-between;
  }
  .filter-list-header-container > * {
    margin-right: 0 !important;
  }
  .filter-list-header-container .search-box {
    flex-basis: 100%;
    order: 1;
  }
  .filter-list-header-container .search-box .search-btn {
    padding-inline: 18px;
  }
  .filter-list-header-container .search-box .dropdown-menu {
    --bs-dropdown-min-width: 6rem;
  }
  .filter-list-header-container .dropdown__filter {
    order: 2;
  }
  .filter-list-header-container .exclusive-listing-btn {
    order: 3;
    margin-left: 0;
  }
  .filter-list-header-container .dropdown__sort-by {
    order: 4;
  }
  .filter-list-header-container .map-search-btn {
    display: none;
  }
  .filter-list-header-container .btn-text {
    display: none;
  }
  .filter-list-header-container #sortDropdownMenu::after,
  .filter-list-header-container #filterDropdownMenu::after {
    display: none;
  }
  .filter-list-header-container .dropdown__sort-by > button,
  .filter-list-header-container .dropdown__filter > button {
    padding: 0 20px;
    font-weight: 600;
    font-size: 14px;
    height: 50px;
    border-radius: 10px;
    background: #d1dff6;
    color: var(--clr-primary) !important;
  }
  .filter-list-header-container .dropdown__sort-by > button:not(.active):hover,
  .filter-list-header-container .dropdown__filter > button:not(.active):hover {
    background: #d1dff6 !important;
  }
  .filter-list-header-container .dropdown__sort-by > button svg,
  .filter-list-header-container .dropdown__filter > button svg {
    margin-right: 0 !important;
  }
  .filter-list-header-container .dropdown__filter .dropdown-menu {
    width: 100vw;
    min-width: auto;
    top: 12px !important;
    left: 0px !important;
    transform: translate3d(-12px, 50px, 0px) !important;
    box-shadow: none;
    padding: 1rem;
  }
  .filter-list-header-container
    .dropdown__filter
    .dropdown-menu
    .price-range-wrapper {
    flex-wrap: wrap;
  }
  .filter-list-header-container
    .dropdown__filter
    .dropdown-menu
    .price-range-wrapper
    .price-range-select-group {
    flex-basis: 100%;
  }
  .filter-list-header-container
    .dropdown__filter
    .dropdown-menu
    .price-range-wrapper
    .price-range-select-group
    .form-group {
    flex: 1;
  }
  /* .filter-list-header-container [aria-labelledby="sortDropdownMenu"] {
    transform: translate3d(calc(-100% + 60px), 60px, 0px) !important;
  } */
}
@media (max-width: 375px) {
  .search-box input {
    width: 175px;
  }
}
