/****banner****/

.new_banner {
  padding: 40px 0;
  position: relative;
  overflow: hidden;
}


.new_banner_content_box {
  background: #e7f1ff;
  border-radius: 29px;
  padding: 30px;
}

.new_banner_content_title h1 {
  line-height: 1;
  font-size: 48px;
}

.new_banner_content_title p span img {
  width: 11px;
}

.new_banner_content_title p span {
  display: inline-block;
  line-height: 0;
  margin-right: 5px;
}

.new_banner_content_title p {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #414141;
  margin-top: 6px;
}

.new_banner_content_title {
  margin-bottom: 10px;
}

.new_banner_content_price h2 {
  line-height: 1;
  font-size: 44px;
  margin-right: 12px;
}

.new_banner_content_price {
  display: flex;
  align-items: end;
}

.new_banner_content_price small {
  color: #525252;
  font-size: 15px;
}

.new_banner_content_price {
  margin-bottom: 20px;
}

ul.slider_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.slider_title li {
  color: #414141;
  font-size: 15px;
}

ul.slider_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

ul.slider_price li {
  color: #4c4c4c;
  font-size: 19px;
}

.new_banner_content_slide small {
  color: #525252;
  font-size: 15px;
  display: block;
  line-height: 1;
}

.slider_bar {
  width: 100%;
  height: 5px;
  background: #b1b1b1;
  border-radius: 23px;
  position: relative;
  margin: 21px 0px;
}

.slider_bar:before {
  content: "";
  position: absolute;
  background: #0061df;
  height: 10px;
  width: 50%;
  border-radius: 36px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.slider_bar_ball:before {
  content: "";
  position: absolute;
  height: 25px;
  width: 25px;
  background: #0061df;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  border-radius: 26px;
}

.slider_bar_ball {
  position: absolute;
  overflow: hidden;
  height: 25px;
  width: 25px;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  border-radius: 42px;
}

.slider_bar_ball:after {
  content: "";
  position: absolute;
  height: 21px;
  width: 21px;
  background: #f5f7f8c9;
  top: -8px;
  bottom: 0;
  margin: auto;
  left: 8px;
  right: 0;
  border-radius: 26px;
}

.slider_bar:after {
  content: "";
  position: absolute;
  background: #f5f7f8c9;
  height: 5px;
  width: 5px;
  top: -1px;
  bottom: 0;
  margin: auto;
  left: 0;
  right: -11px;
  border-radius: 30px;
}

.new_banner_content_button a {
  display: inline-block;
  line-height: 1;
  background: #dc3545;
  padding: 6px 9px;
  color: #fff;
  border: 1px solid #dc3545;
  font-size: 18px;
  border-radius: 4px;
}

.new_banner_content_button {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.new_banner_content_button a:last-child {
  background: #fff;
  color: #dc3545;
  margin-left: 33px;
}

.new_banner_content_button {
  margin-top: 20px;
}

/****selling-option*****/

.new_selling_option_title {
  text-align: center;
  margin-bottom: 50px;
}

.new_selling_option_title h3 {
  color: #0061df;
  font-size: 42px;
}

.new_selling_option {
  padding: 0 0 70px;
}
.new_selling_option_box_content {
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 60px;
  padding: 109px 40px 50px 40px;
  margin-top: 228px;
}

.new_selling_option_box_content_first span {
  display: block;
  font-size: 26px;
  margin-bottom: 25px;
}

.new_selling_option_box_content_first strong {
  font-weight: 400;
  font-size: 23px;
  display: block;
  margin-bottom: 10px;
}

.new_selling_option_box_content_first ul li {
  color: #414141;
  font-size: 16px;
  position: relative;
  margin-left: 31px;
}
.new_selling_option_box_content_first ul li:before {
  content: "";
  position: absolute;
  height: 17px;
  width: 18px;
  background-repeat: no-repeat !important;
  left: -31px;
  top: 0px;
  background-size: 100% 100%;
}

.new_selling_option_box_content_first ul li:not(:last-child) {
  margin-bottom: 8px;
}

.new_selling_option_box_content_first_button {
  margin-top: 30px;
  margin-bottom: 40px;
}

.new_selling_option_box_content_first_button a {
  display: inline-block;
  line-height: 1;
  background: #0061df;
  padding: 6px 23px;
  color: #fff;
  border: 1px solid #0061df;
  font-size: 16px;
  border-radius: 4px;
}

.new_selling_option_box_content_first_button {
  padding: 0;
  color: #0061df;
  background: transparent;
  border: none;
  margin-left: 16px;
}

.new_selling_option_box_content_second span {
  display: block;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 25px;
}

.new_selling_option_box_content_second ul li {
  color: #414141;
  font-size: 16px;
  position: relative;
  margin-left: 31px;
}
.new_selling_option_box_content_second ul li:before {
  content: "";
  position: absolute;
  height: 18px;
  width: 18px;
  background-repeat: no-repeat !important;
  left: -31px;
  top: 1px;
  background-size: 100% 100%;
  opacity: 0.5;
}

.new_selling_option_box_content_second ul li:not(:last-child) {
  margin-bottom: 8px;
}

.new_selling_option_box {
  padding: 0 7px;
  position: relative;
}

.new_selling_option_box_image img {
  width: 345px;
}

.new_selling_option_box_image {
  position: absolute;
  top: -231px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.new_selling_option_box_image.top-adjust {
  top: -201px;
}

/***appo****/
.new_schedule_appointment {
  background: #0060dc14;
  padding: 30px 0;
}

.new_schedule_appointment_content h4 {
  color: #373738;
  font-size: 36px;
  margin-bottom: 25px;
}

.new_schedule_appointment_content p {
  color: #373738;
  font-size: 25px;
  line-height: 1.3;
  margin-bottom: 21px;
}

.new_schedule_appointment_content a {
  display: inline-block;
  line-height: 1;
  background: #0061df;
  padding: 6px 9px;
  color: #fff;
  border: 1px solid #0061df;
  font-size: 18px;
  border-radius: 4px;
}

/***into****/
.new_into_offer_title h5 {
  color: #0061df;
}

.new_into_offer_title {
  text-align: center;
  margin-bottom: 45px;
}

.new_into_offer {
  padding: 50px 0;
}
.new_into_offer_box_button {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

.new_into_offer_box_button ul {
  width: fit-content;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 6px;
}

.new_into_offer_box_button ul li a.active {
  background: #0061df !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);
  border-radius: 7px;
}

.new_into_offer_box_button ul li a {
  color: #707070;
  padding: 0.5rem 1.8rem;
}
.new_property_card_image img {
  width: 100%;
}
.new_property_card_content {
  padding: 14px;
}

.new_property_card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  height: 100%;
}

.new_property_card_title span {
  display: block;
  color: #4b4b4b;
  font-size: 16px;
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.new_property_card_ame ul li {
  color: #373738;
  font-size: 14px;
  width: 50%;
  display: flex;
  align-items: center;
}

.new_property_card_ame ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.new_property_card_ame ul li span {
  display: block;
  line-height: 0;
  margin-right: 2px;
}

.new_property_card_ame ul li span img {
  width: 26px;
}

.new_property_card_ame {
  border-bottom: 1px solid #70707085;
  padding-bottom: 7px;
  margin-bottom: 10px;
}

.new_property_card_p p {
  color: #373738;
  font-size: 15px;
  line-height: 1.3;
  margin-bottom: 5px;
}

.new_property_card_p span {
  display: block;
  color: #373738;
  font-size: 16px;
}

.new_property_card_p span small {
  font-size: 16px;
  text-decoration: line-through;
}

.new_into_offer_box_see_more {
  text-align: center;
  margin-top: 50px;
}

.new_into_offer_box_see_more .seemore {
  color: #0061df;
  font-size: 22px;
  text-decoration: underline;
  border: none;
  background: transparent;
}
